@use "sass:list";
@use "sass:map";
@import "../../../../../../styles/theme/functions/setCustomVars";
@import "../../../../../../styles/theme/functions/setCustomClasses";
@import "../../../../../../styles/theme/functions/ensureVariable";
@import "../../../../../../styles/theme/variables";
$vars:
 //order
  "o",
  //column start
  "cs",
  //column end
  "ce",
  //row start
  "rs",
  //row end
  "re";

.item {
  @include set-custom-vars($vars);
  order: var(--o--responsive);
  grid-column: var(--cs--responsive) / var(--ce--responsive);
  grid-row: var(--rs--responsive) / var(--re--responsive);
  display: grid;
  --o: initial;
  --rs: initial;
  --re: initial;
  --cs: initial;
  --ce: initial;
}
