html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.Toastify__spinner {
  border-color: #ececec !important;
  border-right-color: var(--color-primary) !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #292c30;
}
