@use "sass:list";
@use "sass:map";
@import "../../../../../../styles/theme/functions/setCustomVars";
@import "../../../../../../styles/theme/functions/setCustomClasses";
@import "../../../../../../styles/theme/functions/ensureVariable";
@import "../../../../../../styles/theme/variables";
$vars:
 //columns
  "c",
  // item min width
  "mw",
  // row gap
  "rg",
  // column gap
  "cg";

.grid {
  @include set-custom-vars($vars);

  /**
  * Calculated values.
  Make a grid with a max number of columns. Items will wrap if item width size is below item-min-width variable (accounting column gap widths);
  Link below for further explanations
  https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
  */
  --gap-count: calc(var(--c--responsive) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--cg--responsive));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--c--responsive)
  );

  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--mw--responsive), var(--grid-item--max-width)), 1fr)
  );

  display: grid;
  row-gap: var(--rg--responsive);
  column-gap: var(--cg--responsive);
  // grid-auto-rows: 1fr;
  --c: 3;
  --mw: 25rem;
  --rg: var(--grid-container-row-gap, 2.5rem);
  --cg: var(--grid-container-column-gap, 2.5rem);
}
